import App from './App';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { initializeApp } from 'firebase/app';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';

const firebaseConfig = {
  apiKey: 'AIzaSyAnxakZXjDIeeTponyxLuBTRSq3BDdz2C4',
  authDomain: 'portifolio-78cdd.firebaseapp.com',
  projectId: 'portifolio-78cdd',
  storageBucket: 'portifolio-78cdd.appspot.com',
  messagingSenderId: '514246089640',
  appId: '1:514246089640:web:725fe13078617bf8da5b9d',
  measurementId: 'G-W2K8MM69PE'
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
