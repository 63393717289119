import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import { v4 as uuid } from 'uuid';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Status404 from './content/pages/Status/Status404';
import Login from './content/pages/Login';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
//Jobs
const EditJob = Loader(lazy(() => import('src/content/pages/Jobs/Edit')));
const ListJobs = Loader(lazy(() => import('src/content/pages/Jobs/List')));

//Experiences
const EditExperience = Loader(
  lazy(() => import('src/content/pages/Experiences/Edit'))
);
const ListExperiences = Loader(
  lazy(() => import('src/content/pages/Experiences/List'))
);

//Contacts
const ViewContact = Loader(
  lazy(() => import('src/content/pages/Contacts/View'))
);
const ListContacts = Loader(
  lazy(() => import('src/content/pages/Contacts/List'))
);

//Dashboard
const Dashboard = Loader(lazy(() => import('src/content/pages/Dashboard')));

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'jobs',
    element: <SidebarLayout />,
    children: [
      {
        path: 'edit/:jobId',
        element: <EditJob key={uuid()} />
      },
      {
        path: 'new',
        element: <EditJob key={uuid()} />
      },
      {
        path: 'list',
        element: <ListJobs />
      }
    ]
  },
  {
    path: 'experiences',
    element: <SidebarLayout />,
    children: [
      {
        path: 'edit/:expId',
        element: <EditExperience key={uuid()} />
      },
      {
        path: 'new',
        element: <EditExperience key={uuid()} />
      },
      {
        path: 'list',
        element: <ListExperiences />
      }
    ]
  },
  {
    path: 'contacts',
    element: <SidebarLayout />,
    children: [
      {
        path: 'view/:contactId',
        element: <ViewContact key={uuid()} />
      },
      {
        path: 'list',
        element: <ListContacts />
      }
    ]
  }
];

const loginRoutes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '*',
        element: <Navigate to="/login" replace />
      }
    ]
  }
];

export { routes, loginRoutes };
