import { ReactNode } from 'react';

import MailIcon from '@mui/icons-material/Mail';
import WorkIcon from '@mui/icons-material/Work';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import DashboardIcon from '@mui/icons-material/Dashboard';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        link: '/dashboard',
        icon: DashboardIcon
      }
    ]
  },
  {
    heading: 'Jobs',
    items: [
      {
        name: 'New Job',
        link: '/jobs/new',
        icon: BookmarkAddIcon
      },
      {
        name: 'List Jobs',
        link: '/jobs/list',
        icon: BookmarksIcon
      }
    ]
  },
  {
    heading: 'Contacts',
    items: [
      {
        name: 'List Contacts',
        link: '/contacts/list',
        icon: MailIcon
      }
    ]
  },
  {
    heading: 'Experiences',
    items: [
      {
        name: 'New Experience',
        link: '/experiences/new',
        icon: WorkIcon
      },
      {
        name: 'List Experiences',
        link: '/experiences/list',
        icon: WorkspacesIcon
      }
    ]
  }
];

export default menuItems;
