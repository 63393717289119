import { useRoutes } from 'react-router-dom';
import { routes, loginRoutes } from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

const App = () => {
  const [user, loading] = useAuthState(getAuth());

  const RenderRoutes = () => {
    const actualRoutes = user ? routes : loginRoutes;

    return useRoutes(actualRoutes);
  };

  const RenderLoadingRoute = () => {
    return useRoutes([
      {
        path: '*',
        element: <></>
      }
    ]);
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {loading ? RenderLoadingRoute() : RenderRoutes()}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
