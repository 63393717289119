import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);
