import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
  TextField,
  Grid
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { FieldList, MainContent } from './styles';
import { useCallback, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  const navigate = useNavigate();

  const login = useCallback(async () => {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, pwd);
      navigate('/dashboard');
    } catch (err) {
      alert(err.message);
    }
  }, [email, pwd, navigate]);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <img alt="404" height={180} src="/static/images/status/login.svg" />
          </Box>
          <Container maxWidth="sm">
            <Card sx={{ textAlign: 'center', mt: 3, p: 4 }}>
              <Box textAlign="left">
                <Typography variant="h2" sx={{ my: 2 }}>
                  Sign in
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 4 }}
                >
                  Sign in and publish new content to the website
                </Typography>
              </Box>
              <Divider sx={{ my: 4 }}></Divider>
              <FieldList>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  required
                  label="Password"
                  type="password"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </FieldList>
              <Grid container justifyContent="flex-end">
                <Button sx={{ mt: 2 }} onClick={login} variant="contained">
                  Sign in
                </Button>
              </Grid>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}

export default Login;
